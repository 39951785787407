import { RegionId as PrismaRegionId } from '@prisma/client';
import { type ReportType } from 'report/reportTypes';

export const regionIds = Object.values(PrismaRegionId);

export type RegionId = PrismaRegionId;

export function getRegionId(value: string | null | undefined): RegionId | null {
    if (!value) {
        return null;
    }

    if (!regionIds.includes(value as RegionId)) {
        return null;
    }

    return value as RegionId;
}

// these region names are not localized
export const regionLabels: Record<RegionId, string> = {
    au: 'Australia',
    ca: 'Canada',
    latam: 'LATAM',
    nl: 'Netherlands',
    nz: 'New Zealand',
    us: 'United States',
};

// these region reporting titles are localized
export const regionTitles: Record<RegionId, string> = {
    au: 'Australian Podcast Ranker',
    ca: 'Canada Podcast Ranker',
    latam: 'Latin America Podcast Ranker',
    nl: 'Netherlands Podcast Ranker',
    nz: 'New Zealand Podcast Ranker',
    us: 'US Podcast Ranker',
};

// the order of the reportSets is important as the first report set of a regionId is the default report set
export const reportSets = defineReportSets([
    {
        regionId: 'au',
        slug: 'podcasts',
    },
    {
        regionId: 'au',
        slug: 'podcasts-aus',
    },
    {
        regionId: 'au',
        slug: 'networks',
    },
    {
        regionId: 'au',
        slug: 'salesreps',
    },
    {
        regionId: 'ca',
        slug: 'podcasts',
    },
    {
        regionId: 'ca',
        slug: 'networks',
    },
    {
        regionId: 'ca',
        slug: 'podcasts-english',
    },
    {
        regionId: 'ca',
        slug: 'podcasts-french',
    },
    {
        regionId: 'latam',
        slug: 'podcasts',
    },
    {
        regionId: 'latam',
        slug: 'networks',
    },
    {
        regionId: 'nl',
        slug: 'podcasts',
    },
    {
        regionId: 'nl',
        slug: 'networks',
    },
    {
        regionId: 'nz',
        slug: 'podcasts',
    },
    {
        regionId: 'nz',
        slug: 'networks',
    },
    {
        regionId: 'us',
        slug: 'podcasts-weekly-downloads',
    },
    {
        regionId: 'us',
        slug: 'podcasts-weekly-users',
    },
    {
        regionId: 'us',
        slug: 'networks-weekly-downloads',
    },
    {
        regionId: 'us',
        slug: 'networks-weekly-users',
    },
]);

export type ReportSet = (typeof reportSets)[number];

export function getReportSetsByRegionId<T extends RegionId>(regionId: T): Extract<ReportSet, { regionId: T }>[] {
    // we have to assert that .filter returns what the return type expects
    return reportSets.filter((reportSet) => reportSet.regionId === regionId) as Extract<ReportSet, { regionId: T }>[];
}

export function getReportSetBySlug(regionId: RegionId, slug: string): ReportSet | null {
    return reportSets.find((reportSet) => reportSet.regionId === regionId && reportSet.slug === slug) ?? null;
}

export function getDefaultReportSet(regionId: RegionId): ReportSet | null {
    // get the first reportSet that matches the specified regionId
    return reportSets.find((reportSet) => reportSet.regionId === regionId) ?? null;
}

export function getReportType(reportSet: ReportSet): ReportType {
    switch (reportSet.regionId) {
        case 'au': {
            switch (reportSet.slug) {
                case 'networks': {
                    return 'networkAu';
                }

                case 'podcasts': {
                    return 'podcastAu';
                }

                case 'podcasts-aus': {
                    return 'podcastAu';
                }

                case 'salesreps': {
                    return 'salesRepresentativeAu';
                }
            }

            break;
        }

        case 'ca': {
            switch (reportSet.slug) {
                case 'networks': {
                    return 'networkCa';
                }

                case 'podcasts': {
                    return 'podcastUs';
                }

                case 'podcasts-english': {
                    return 'podcastUs';
                }

                case 'podcasts-french': {
                    return 'podcastUs';
                }
            }

            break;
        }

        case 'latam': {
            switch (reportSet.slug) {
                case 'podcasts': {
                    return 'podcastLatam';
                }
                case 'networks': {
                    return 'networkLatam';
                }
            }

            break;
        }

        case 'nl': {
            switch (reportSet.slug) {
                case 'networks': {
                    return 'networkNl';
                }

                case 'podcasts': {
                    return 'podcastNl';
                }
            }

            break;
        }

        case 'nz': {
            switch (reportSet.slug) {
                case 'networks': {
                    return 'networkNz';
                }

                case 'podcasts': {
                    return 'podcastNz';
                }
            }

            break;
        }

        case 'us': {
            switch (reportSet.slug) {
                case 'networks-weekly-downloads': {
                    return 'networkUsDownloads';
                }

                case 'networks-weekly-users': {
                    return 'networkUsUsers';
                }

                case 'podcasts-weekly-downloads': {
                    return 'podcastUs';
                }

                case 'podcasts-weekly-users': {
                    return 'podcastUs';
                }
            }

            break;
        }
    }
}

export function getReportSetTitle(reportSet: ReportSet): string {
    switch (reportSet.regionId) {
        case 'au': {
            switch (reportSet.slug) {
                case 'networks': {
                    return 'Top Publishers';
                }

                case 'podcasts': {
                    return 'Top Podcasts';
                }

                case 'podcasts-aus': {
                    return 'Top Podcasts (Australian Made)';
                }

                case 'salesreps': {
                    return 'Top Sales Representatives';
                }
            }

            break;
        }

        case 'ca': {
            switch (reportSet.slug) {
                case 'networks': {
                    return 'Top Sales Networks';
                }

                case 'podcasts': {
                    return 'Top Podcasts';
                }

                case 'podcasts-english': {
                    return 'Canadian Made English Podcasts';
                }

                case 'podcasts-french': {
                    return 'Canadian Made French Podcasts';
                }
            }

            break;
        }

        case 'latam': {
            switch (reportSet.slug) {
                case 'podcasts': {
                    return 'Top Podcasts';
                }
                case 'networks': {
                    return 'Top Sales Networks';
                }
            }

            break;
        }

        case 'nl': {
            switch (reportSet.slug) {
                case 'networks': {
                    return 'Top Networks';
                }

                case 'podcasts': {
                    return 'Top Podcasts';
                }
            }

            break;
        }

        case 'nz': {
            switch (reportSet.slug) {
                case 'networks': {
                    return 'Top Networks';
                }

                case 'podcasts': {
                    return 'Top Podcasts';
                }
            }

            break;
        }

        case 'us': {
            switch (reportSet.slug) {
                case 'networks-weekly-downloads': {
                    return 'Top Sales Networks By Weekly Average Downloads';
                }

                case 'networks-weekly-users': {
                    return 'Top Sales Networks By Weekly Average Users';
                }

                case 'podcasts-weekly-downloads': {
                    return 'Top Podcasts By Weekly Average Downloads';
                }

                case 'podcasts-weekly-users': {
                    return 'Top Podcasts By Weekly Average Users';
                }
            }

            break;
        }
    }
}

function defineReportSets<Slug extends string, T extends { regionId: RegionId; slug: Slug }>(t: T[]): T[] {
    return t;
}
